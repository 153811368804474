import { store } from "redux/store";

const isResourceAvailable = (role, resource, action) => {
  let resourceData = store.getState()?.data?.userResources;
  console.log(resourceData, "resourceData");
  if (resourceData?.resources && resourceData?.resources[resource]) {
    if (Array.isArray(resourceData?.resources[resource])) {
      return resourceData?.resources[resource].includes(action);
    }
  }

  if (resourceData?.role) {
    return resourceData?.[role];
  }

  return false;
};

export default isResourceAvailable;
