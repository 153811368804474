import { SearchFilter } from "Components/AttendaceReport";
import ActionLeave from "Components/LeaveRequest/ActionLeave";
import LeaveView from "Components/LeaveRequest/LeaveView";
import { Table } from "UI_Components";
import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";

import apiManager from "networking/baseAPIManager";

import React, { useEffect, useState } from "react";
import { Pagination } from "rsuite";

export default function ResignationApprove() {
  const [searchString, setSearchString] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [view, setView] = useState(false);
  const [offboardingApprovalData, setOffboardingApprovalData] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [dataPayload, setdataPayload] = useState({});
  const [filteredApprovalData, setFilteredApprovalData] = useState(offboardingApprovalData);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredApprovalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredApprovalData]);

  // console.log(offboardingApprovalData, "offboardingApprovalData");

  const getOffboardingApprovalData = () => {
    setLoading(true);
    apiManager
      .OffboardingPendingList("pending")
      .then((response) => {
        setOffboardingApprovalData(response?.data?.List);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!confirm) {
      getOffboardingApprovalData();
    }
  }, [confirm]);

  useEffect(() => {
    if (searchString) {
      const result = offboardingApprovalData.filter((rowObj) => {
        return (
          rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.employee_name.toLowerCase().includes(searchString.toLowerCase())
        );
      });
      setFilteredApprovalData(result);
    } else {
      setFilteredApprovalData(offboardingApprovalData);
    }
  }, [offboardingApprovalData, searchString]);

  const handleApproveReject = (flag, rowData) => {
    if (flag === "approve") {
      setdataPayload({
        id: rowData._id,
        status: "approved",
      });
    } else {
      setdataPayload({
        id: rowData._id,
        status: "rejected",
      });
    }

    setOpen(true);
    setView(false);
    setDeleteModal(true);
  };
  const handleRejectconfirm = () => {
    apiManager
      .OffboardingStatus(dataPayload)
      .then((res) => {
        setLoading(true);
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    confirm === true && handleRejectconfirm();
    setConfirm(false);
  }, [confirm]);

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" sizeclass="" />;
  };

  const customHtml1 = (data) => {
    return <ActionLeave rowData={data} handleApproveReject={(flag, data) => handleApproveReject(flag, data)} />;
  };

  const customHtml2 = (data) => (
    <div className="d-flex gap-3">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );
  var moment = require("moment");
  const formatDate = (date) => moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("LL");
  const columns = [
    { header: "S.No", field: "" },
    // { header: "Employee Name", field: "employeeName" },
    { header: "Employee Name", field: "employee_name" },
    { header: "Resignation Type", field: "resignation_type" },
    { header: "Notice Date", field: "notice_date", customHtml: (data) => formatDate(data.notice_date) },
    { header: "Resignation Date", field: "resignation_date", customHtml: (data) => formatDate(data.resignation_date) },
    { header: "", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];
  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
    setView(true);
  };
  return (
    <>
      <div className="Container  ">
        <div className="row m-0 p-0">
          <div className="col-lg-3 mt-4">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Search Employee By Name/ID"}
            />
          </div>
        </div>
        <div className="col-lg-12 px-3 pt-3 tableContainer">
          <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

          <Table
            columns={columns}
            Data={displayedData}
            btn_name="view"
            LeaveTableHeight="LeaveTableHeight"
            secondary_btn_label="Action "
          />
        </div>

        <div className="pt-4 d-flex justify-content-end">
          <Pagination
            prev
            last
            next
            first
            size="sm"
            total={filteredApprovalData.length}
            limit={itemsPerPage}
            activePage={activePage}
            onChangePage={setActivePage}
          />
        </div>

        <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "sm" : "xs"} title="Resignation Details">
          {view ? <LeaveView Data={modalContent} /> : null}
          {deleteModal ? <ConfirmationModal setConfirm={setConfirm} setOpen={setOpen} /> : null}
        </CommonModal>

        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
}
