import AccordionWrapper from "common/components/AccordionWrapper";
import moment from "moment";
import React from "react";

const ProfileAccordion = ({ data }) => {
  const Add = data?.ParmanentAddress || {};

  let Address = "";
  if (Add)
    Address =
      (Add.line1 || "") +
      " " +
      (Add.line2 || "") +
      " " +
      (Add.line3 || "") +
      " " +
      (Add.cityname || "") +
      " ," +
      (Add.countryname || "");

  let checkAddress = String(Address).replaceAll(" ", "");

  if (checkAddress === "," || checkAddress === "undefined") {
    Address = "null";
  }

  let bankdetails = data?.bankdetails || {};
  let femilyMemberFullName =
    (data?.family_member_first_name !== undefined ? data?.family_member_first_name : "NA") +
    " " +
    (data?.family_member_last_name !== undefined ? data?.family_member_last_name : "NA");
  let documents = data?.documents || [{}];
  documents = documents[0];
  console.log(data, "data==========");
  return (
    <>
      <AccordionWrapper title={"Personal Information"} eventKey={"#flush-collapseOne"} id={"flush-collapseOne"}>
        <div className="row">
          <div className="col-lg-12 ">
            <ul className="drawerUserDetail">
              <li className="row">
                <span className="col-lg-5">Telphone No. </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">{data.mobile_number ? data.mobile_number : "NA"}</span>
              </li>
              <li className="row">
                <span className="col-lg-5">Address</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">{Address ? Address : "NA"}</span>
              </li>
              {/* <li className="row">
                <span className="col-lg-6">Nationality :</span>
                <span className="col-lg-6 text-dark-emphasis">null</span>
              </li>
              <li className="row">
                <span className="col-lg-6">Religion :</span>
                <span className="col-lg-6 text-dark-emphasis">null</span>
              </li>
              <li className="row">
                <span className="col-lg-6">Marital status :</span>
                <span className="col-lg-6 text-dark-emphasis">null</span>
              </li>
              <li className="row">
                <span className="col-lg-6">Employment of spouse :</span>
                <span className="col-lg-6 text-dark-emphasis">null</span>
              </li>
              <li className="row">
                <span className="col-lg-6">No. of children :</span>
                <span className="col-lg-6 text-dark-emphasis">null</span>
              </li> */}
            </ul>
          </div>
        </div>
      </AccordionWrapper>
      <AccordionWrapper title={"Emergency Contact"} eventKey={"#flush-collapseTwo"} id={"flush-collapseTwo"}>
        <div className="row">
          <div className="col-lg-12 ">
            <ul className="drawerUserDetail">
              <li className="row">
                <span className="col-lg-5">Name</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {femilyMemberFullName ? femilyMemberFullName : "NA"}
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5">Relationship</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">{data?.relationship ? data?.relationship : "NA"}</span>
              </li>
              <li className="row">
                <span className="col-lg-5">Phone</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {data?.emergency_number ? data?.emergency_number : "NA"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </AccordionWrapper>
      <AccordionWrapper title={"Bank Information "} eventKey={"#flush-collapseThree"} id={"flush-collapseThree"}>
        <div className="row">
          <div className="col-lg-12 ">
            <ul className="drawerUserDetail">
              <li className="row">
                <span className="col-lg-5">Bank Name </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {bankdetails.bank_name ? bankdetails.bank_name : "NA"}
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5">Bank Account No. </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {bankdetails.account_no ? bankdetails.account_no : "NA"}
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5">IFSC Code </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {bankdetails.ifsc_code ? bankdetails.ifsc_code : "NA"}
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5">PAN No.</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">{data.pancard_no ? data.pancard_no : "NA"}</span>
              </li>
              <li className="row">
                <span className="col-lg-5">Aadhaar No.</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">{data.aadharcard_no ? data.aadharcard_no : "NA"}</span>
              </li>
            </ul>
          </div>
        </div>
      </AccordionWrapper>
      <AccordionWrapper title={"Family Information "} eventKey={"#flush-collapseFour"} id={"flush-collapseFour"}>
        <div className="row">
          <div className="col-lg-12 ">
            <ul className="drawerUserDetail">
              <li className="row">
                <span className="col-lg-5">Name </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis text-capitalize">
                  {femilyMemberFullName ? femilyMemberFullName : "NA"}
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5">Relationship </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">{data?.relationship ? data?.relationship : "NA"}</span>
              </li>
              <li className="row">
                <span className="col-lg-5">Date of Birth </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {data?.family_member_dob ? moment(data?.family_member_dob).format("DD MMM YYYY") : "NA"}
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5"> Phone </span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {data?.family_member_phone ? data?.family_member_phone : "NA"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </AccordionWrapper>
      <AccordionWrapper title={"Education Information "} eventKey={"#flush-collapseFive"} id={"flush-collapseFive"}>
        <div className="row">
          <div className="col-lg-12 ">
            <ul className="drawerUserDetail">
              <li className="row">
                <span className="col-lg-5"> Secondary Passing</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {documents?.secondary_passing ? documents?.secondary_passing : "NA"} %
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5"> Senior Passing</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {documents?.senior_passing ? documents?.senior_passing : "NA"} %
                </span>
              </li>
              <li className="row">
                <span className="col-lg-5"> Bachelor's Passing</span>
                <span className="col-lg-1"> :</span>
                <span className="col-lg-6 text-dark-emphasis">
                  {documents?.bachelor_passing ? documents?.bachelor_passing : "NA"} %
                </span>
              </li>
              {documents?.extra &&
                documents?.extra?.map((extraDetail, index) => {
                  return (
                    <>
                      <li className="row">
                        <span className="col-lg-5"> {extraDetail?.degreeField}</span>
                        <span className="col-lg-1"> :</span>
                        <span className="col-lg-6 text-dark-emphasis">
                          {extraDetail?.degreepercent ? extraDetail?.degreepercent : "NA"}%
                        </span>
                      </li>
                    </>
                  );
                })}
            </ul>
          </div>
        </div>
      </AccordionWrapper>
    </>
  );
};

export default ProfileAccordion;
