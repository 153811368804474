import React, { useEffect, useState } from "react";
import { Button } from "common/components";
import { Table } from "UI_Components";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";

const Completed = (props) => {
  const { data } = props;
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = data.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, data]);

  const customHtml = (data) => {
    return (
      <Button
        label="View Details"
        onClick={() => {
          navigate(`/hrtools/employee-onboarding/candidatePreview/${data._id}`);
        }}
      />
    );
  };

  const columns = [
    { header: "S.No", field: "" },
    { header: "ID", field: "employeeID" },
    {
      header: "Name",
      field: "firstname",
      customHtml: (data) => {
        const fullName = `${data.firstname} ${data.middlename ? data.middlename : ""} ${data.lastname}`;
        return <span>{fullName}</span>;
      },
    },
    { header: "Mobile No.", field: "mobile_number" },
    // { header: "Onboarding Department", field: "joiningDepartment" },
    // { header: "Onboarding Start", field: "onbordingstart" },
    // { header: "Owner ID", field: "ownerDepartment" },
    { header: "", field: "actions", customHtml: customHtml },
  ];
  // const handleClick = (data) => {
  //   navigate("/hrtools/employee-onboarding/candidatedetails", { state: data });
  // };
  return (
    <div className="container-fluid  m-0  p-0">
      <div className="row p-1 m-3 pt-2 ">
        <div className="table_wrapper">
          {/* <OnboardingTable filteredData={data} setSelectType={setSelectType} /> */}
          <Table columns={columns} Data={displayedData} />
        </div>
        <div className="pt-5 d-flex justify-content-end">
          <Pagination
            prev
            last
            next
            first
            size="sm"
            total={data.length}
            limit={itemsPerPage}
            activePage={activePage}
            onChangePage={setActivePage}
          />
        </div>
      </div>
    </div>
  );
};

export default Completed;
