import LeaveView from "Components/LeaveRequest/LeaveView";
import { Table } from "UI_Components";
import { Button, CommonModal, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { Pagination } from "rsuite";

export default function ResignationComplete() {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [open, setOpen] = useState(false);

  const [view, setView] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [offboardingCompleteData, setOffboardingCompleteData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = offboardingCompleteData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, offboardingCompleteData]);

  const getOffboardingCompleteData = () => {
    setLoading(true);
    apiManager
      .OffboardingCompleteList()
      .then((response) => {
        // console.log(response?.data?.List);
        setOffboardingCompleteData(response?.data?.List || []);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOffboardingCompleteData();
  }, [open]);

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };
  const moment = require("moment");
  const formatDate = (date) => moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("LL");

  const columns = [
    { header: "S.No", field: "" },
    // { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "employeeName" },
    { header: "Resignation Type", field: "resignation_type" },
    { header: "Notice Date", field: "notice_date", customHtml: (data) => formatDate(data.notice_date) },
    { header: "Resignation Date", field: "resignation_date", customHtml: (data) => formatDate(data.notice_date) },
    {
      header: "Status",
      field: "offboarding_status",
      customHtml: (data) => {
        const statusColor =
          data.offboarding_status.toLowerCase() === "approved"
            ? "green"
            : data.offboarding_status.toLowerCase() === "rejected"
            ? "red"
            : "blue";

        return <span style={{ color: statusColor }}>{data.offboarding_status}</span>;
      },
    },

    { header: "", field: "actions", btnType: "primary", customHtml },
  ];
  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
    setView(true);
  };

  return (
    <div className="Container  ">
      <div className="row p-3 m-0">
        {/* <div className="col-md-4 ">
          <SelectPickerInput
            sidelabel="Select Year"
            defaultselect={currentYear}
            data={dropdownData}
            value={selectedYear}
            valueKey={"value"}
            onChange={handleYearChange}
          />
        </div> */}

        <div className="col-lg-12 px-3  tableContainer">
          <Table
            columns={columns}
            Data={displayedData}
            btn_name="view"
            LeaveTableHeight="LeaveTableHeight"
            custom_btn="custom_btn"
            custom_btn2="custom_btn2"
          />
        </div>

        <div className="pt-4 d-flex justify-content-end">
          <Pagination
            prev
            last
            next
            first
            size="sm"
            total={offboardingCompleteData.length}
            limit={itemsPerPage}
            activePage={activePage}
            onChangePage={setActivePage}
          />
        </div>
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "sm" : "xs"} title="Resignation Details">
        {view ? <LeaveView Data={modalContent} showButton={true} setOpen={setOpen} /> : null}
      </CommonModal>
      <CoreLoader show={loading} />
    </div>
  );
}
