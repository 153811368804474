import { SearchFilter } from "Components/AttendaceReport";
import SubHeader from "UI_Components/SubHeader";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./../../../assets/style/EmployeeProfile/EmployeeProfile.css";
import { Dropdown } from "common/components";
import ProfileCard from "Components/EmployeeProfile/ProfileCard";
import apiManager from "networking/baseAPIManager";
import { useSelector, useDispatch } from "react-redux";

const EmployeeProfile = () => {
  const refresh = useSelector((state) => state.data.refresh);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [employeeOnleave, setEmployeeOnleave] = useState([]);
  const [employeeDetail, setEmployeeDetail] = useState([]);
  const [filteredApprovalData, setFilteredApprovalData] = useState([]);
  const [getDesignation, setDesignation] = useState([]);
  const [getDepartment, setDepartment] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const location = useLocation();
  const flagFromAttendance = location.state;

  let designation = selectedDepartment == "" ? selectedDepartment : selectedDepartment?.toLocaleLowerCase();

  useEffect(() => {
    fetchEmployeesOnLeave();
    if (designation) {
      getDesignationList();
    }
    getDepartmentList();
  }, [designation]);

  const fetchEmployeesOnLeave = () => {
    setLoading(true);
  };
  const getDepartmentList = () => {
    setLoading(true);
    apiManager
      .getDropdownTypes("department")
      .then((response) => {
        setLoading(false);
        setDepartment(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const getDesignationList = () => {
    console.log(designation, "designation");
    setLoading(true);
    apiManager
      .getDesignation(designation)
      .then((response) => {
        setLoading(false);
        console.log(response?.data?.data, "Response");
        setDesignation(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    let status = flagFromAttendance === "leave" ? "?status=onleave" : "?status=active";
    apiManager
      .activeEmployeeList(status)
      .then((response) => {
        setLoading(false);
        if (status === "?status=onleave") {
          setEmployeeOnleave(response.data);
        } else {
          setEmployeeDetail(response.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [flagFromAttendance, refresh]);

  useEffect(() => {
    if (selectedDepartment !== "ALL DEPARTMENT") {
      const filteredEmployees = employeeDetail.filter((employee) => employee?.department === selectedDepartment);
      setFilteredApprovalData(filteredEmployees);
    } else {
      setFilteredApprovalData(employeeDetail);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedDesignation !== "All") {
      const filteredEmployees = employeeDetail.filter((employee) => employee?.designation === selectedDesignation);
      setFilteredApprovalData(filteredEmployees);
    } else {
      setFilteredApprovalData(employeeDetail);
    }
  }, [selectedDesignation]);

  useEffect(() => {
    if (searchString) {
      const result = filteredApprovalData.filter(
        (rowObj) =>
          rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.firstname.toLowerCase().includes(searchString.toLowerCase())
      );
      setFilteredApprovalData(result);
    } else {
      setFilteredApprovalData(employeeDetail);
    }
  }, [employeeDetail, searchString]);

  return (
    <div className="container-fluid">
      <SubHeader location={location} />
      <div className="row mt-4">
        <div className="col-lg-4 col-md-6 mb-3">
          <SearchFilter
            data={searchString}
            setSearchString={setSearchString}
            placeholder="Search Employee By Name/ID"
          />
        </div>
        <div className="col-lg-3 col-md-6 mb-3">
          <Dropdown
            value={selectedDepartment}
            options={getDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            placeholder="Select Department"
          />
        </div>
        <div className="col-lg-3 col-md-6 mb-3">
          <Dropdown
            value={selectedDesignation}
            options={getDesignation}
            onChange={(e) => setSelectedDesignation(e.target.value)}
            placeholder="Select Designation"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <ProfileCard
            flagFromAttendance={flagFromAttendance}
            filteredApprovalData={filteredApprovalData}
            employeeOnleave={employeeOnleave}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfile;
