import { SCard, SubHeader, Table } from "UI_Components";
import React, { useEffect, useState } from "react";
import candidates from "../../../assets/images/candidates.png";
import employeeId from "../../../assets/images/employeeId.png";
import joiningDate from "../../../assets/images/joiningDate.png";
import department from "../../../assets/images/department.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, CoreLoader, Dropdown, Input } from "common/components";
import CalendarApp from "common/components/CalendarApp";
import CircularBar from "common/components/CircularBar";
import apiManager from "networking/baseAPIManager";
import { BsArrowRightCircle } from "react-icons/bs";
import "../../../assets/style/Responsive.css";
import moment from "moment";

export default function EmployeeDashboard() {
  const [isOpen, setIsOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [employeeSalarylistData, setEmployeeSalarylistData] = useState({});
  const [loading, setLoading] = useState(false);
  const [InformationData, setInformationData] = useState();
  const [CheackInOutData, setCheackInOutData] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("checkedOut");
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [breakTime, setBreakTime] = useState({ hours: 0, minutes: 0 });
  const [overtimeMinutes, setOvertimeMinutes] = useState({ hours: 0, minutes: 0 });
  const [remainingTime, setRemainingTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const navigate = useNavigate();

  const formatTime = ({ hours, minutes, seconds }, includeSeconds = true) => {
    if (includeSeconds) {
      return `${hours} Hrs: ${minutes} Min: ${seconds} Sec`;
    } else {
      return `${hours} Hrs: ${minutes} Min`;
    }
  };

  const formatISOTime = (isoString) => moment(isoString).format("LT");
  const getDayOfWeek = (isoString) => moment(isoString).format("dddd");
  const formatDate = (date) => moment(date).format("DD MMM YYYY");

  const tools = [
    {
      cardTitle: `${InformationData?.FullName || "N/A"}`,
      cardContent: `${InformationData?.Designation || "N/A"}`,
      image: candidates,
    },
    {
      cardTitle: "Employee ID",
      cardContent: `${InformationData?.EmployeeID || "N/A"}`,
      image: employeeId,
    },
    {
      cardTitle: "Joining Date",
      image: joiningDate,
      cardContent: `${formatDate(InformationData?.JoiningDate) || "N/A"}`,
    },
    {
      cardTitle: "Department",
      cardContent: `${InformationData?.Department || "N/A"}`,
      image: department,
    },
  ];

  const columns = [
    { header: "S.No", field: "sno" },
    { header: "Date", field: "date", customHtml: (data) => formatDate(data.date) },
    { header: "Day", field: "date", customHtml: (data) => getDayOfWeek(data.date) },
    { header: "CheckIn", field: "checkInTime", customHtml: (data) => formatISOTime(data.checkInTime) },
    {
      header: "CheckOut",
      field: "checkOutTime",
      customHtml: (data) => (data.checkOutTime ? formatISOTime(data.checkOutTime) : "--"),
    },
    {
      header: "Working Hour",
      customHtml: (data) => {
        const { hours, minutes, seconds } = data;
        return `${hours}h : ${minutes}m : ${seconds}s`;
      },
    },
  ];

  useEffect(() => {
    const savedStatus = localStorage.getItem("checkInStatus");
    const savedTimer = localStorage.getItem("checkInTimer");
    if (savedStatus) setCurrentStatus(savedStatus);
    if (savedTimer) setTimer(JSON.parse(savedTimer));
    PersonalInformationData();
    EmployeeTableData();
    EmployeeSalaryStatuslist();
  }, []);

  useEffect(() => {
    let interval;

    if (currentStatus === "checkedIn") {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          const seconds = prevTimer.seconds + 1;
          if (seconds === 60) {
            const minutes = prevTimer.minutes + 1;
            if (minutes === 60) {
              const hours = prevTimer.hours + 1;
              return { hours, minutes: 0, seconds: 0 };
            }
            return { ...prevTimer, minutes, seconds: 0 };
          }
          return { ...prevTimer, seconds };
        });

        localStorage.setItem("checkInTimer", JSON.stringify(timer));
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentStatus, timer]);

  const handleCheckInOut = () => {
    setLoading(true);
    const currentTime = new Date().toISOString();
    const newStatus = currentStatus === "checkedOut" ? "checkedIn" : "checkedOut";

    const payload = {
      status: newStatus,
      time: currentTime,
    };

    apiManager
      .CheackInCheckOut(payload)
      .then((response) => {
        setLoading(false);
        setCheackInOutData(response.data);
        setCurrentStatus(newStatus);
        localStorage.setItem("checkInStatus", newStatus);
        if (newStatus === "checkedOut") {
          setTimer({ hours: 0, minutes: 0, seconds: 0 });
          localStorage.removeItem("checkInTimer");
        }
        setBreakTime(response.data.breakTime);
        setOvertimeMinutes(response.data.overtime);
        setRemainingTime(response.data.remainingTime);
        EmployeeTableData();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const PersonalInformationData = () => {
    setLoading(true);

    apiManager
      .PersonalInformation()
      .then((response) => {
        setLoading(false);
        setInformationData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const EmployeeTableData = () => {
    setLoading(true);

    apiManager
      .EmployeedataTable()
      .then((response) => {
        setLoading(false);
        const empdata = response.data?.empdata.map((entry, index) => {
          const checkOutTime = entry.checkOutTime ? entry.checkOutTime : "";
          return {
            ...entry,
            sno: index + 1,
            checkOutTime,
          };
        });
        setEmployeeData(empdata);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const EmployeeSalaryStatuslist = () => {
    setLoading(true);

    apiManager
      .EmployeeSalaryStatus()
      .then((response) => {
        setLoading(false);

        setEmployeeSalarylistData(response.data.salaryDetails);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  // console.log(employeeSalarylistData, "employeeSalarylistData");
  return (
    <div>
      <div>
        <SubHeader location={useLocation()} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <SCard tools={tools} titleClass="text-light" contentClass="text-light" />
          </div>
        </div>

        <div className="row p-3">
          <div className="col-lg-6 col-xl-4">
            <div className="border rounded-4 chartboxshadow">
              <div className="row p-3 align-items-center">
                <div className="col-xl-8">
                  <h5 className="fw-bold">Statistics</h5>
                </div>
                <div className="col-xl-4">
                  <Button className=" bg-light px-4 py-2 fs-5 text-primary-emphasis" label="Today" />
                </div>
              </div>
              <div className="row p-3 border rounded-4 chartboxshadow m-3">
                <div className="col-lg-12 mt-3" style={{ color: "rgb(28, 63, 152)" }}>
                  <h5>Work Time</h5>
                </div>
                <div className="col-lg-10">
                  <h5>{formatTime(timer)}</h5>
                </div>
                <div className="col-lg-12 d-flex justify-content-end mb-2">
                  <Button
                    label={currentStatus === "checkedOut" ? "Check-In" : "Check-Out"}
                    onClick={handleCheckInOut}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="row p-3">
                <div className="col-xl-6 col-lg-6 col-xxl-4 col-md-6">
                  <div className="border rounded-4 p-2 chartboxshadow text-center">
                    <h6>Remaining</h6>
                    <span>{formatTime(remainingTime, false)}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-xxl-4 col-md-6 ">
                  <div className="border rounded-4 p-2 chartboxshadow text-center ">
                    <h6>Over Time</h6>
                    <span>{formatTime(overtimeMinutes, false)}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-xxl-4 col-lg-6 col-md-6 ">
                  <div className="border rounded-4 p-2 chartboxshadow text-center m-b-14">
                    <h6>Break Time</h6>
                    <span>{formatTime(breakTime, false)}</span>
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-lg-12">
                  <Link to="/hrtools/attendance/attendance-report" style={{ textDecoration: "none", color: "inherit" }}>
                    <span>View Attendance</span>
                    <i className="bi bi-arrow-right-circle-fill" style={{ color: "red", margin: "10px" }}>
                      <BsArrowRightCircle />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-xl-8 salary">
            <div className="chartboxshadow border rounded-4">
              <div>
                <CalendarApp size="sm" />
              </div>
            </div>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-lg-8 col-xl-9 col-md-12 " style={{ height: "450px" }}>
            <Table Data={employeeData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
          </div>
          <div className="col-lg-4 col-xl-3 col-md-6 mari border rounded-4 chartboxshadow salary">
            <div className="row p-3">
              <div className="col-lg-6">
                <h5>Payslip</h5>
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <CircularBar days={28} color="#rgb(28, 63, 152)" height="150px" width="150px" />
            </div>
            <div className="row p-4">
              <table className="m-3 salaery">
                <tbody>
                  <tr>
                    <th className="">Gross Pay</th>
                    <td className="">{employeeSalarylistData?.totalCTC}</td>
                  </tr>
                  <tr>
                    <th className="">Net Pay</th>
                    <td className="">{employeeSalarylistData?.netSalary}</td>
                  </tr>
                  <tr>
                    <th className="">Deduction</th>
                    <td className="">{employeeSalarylistData?.miscellaneous}</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-lg-12 p-3">
                <Link to="/hrtools/manage-fund" style={{ textDecoration: "none", color: "inherit" }}>
                  <span>Show Salary</span>
                  <i className="bi bi-arrow-right-circle-fill" style={{ color: "red", margin: "10px" }}>
                    <BsArrowRightCircle />
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <CoreLoader show={loading} />
      </div>
    </div>
  );
}
