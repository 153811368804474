import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ManageFundView({
  data,
  setIsOpen,
  showPayButton,
  showPayHoldButton,
  showPaySlipButton,
  isOpen,
  setmsgType,
  setShowMSG,
}) {
  const [loading, setLoading] = useState(false);
  const [advansSalaryData, setAdvansSalaryData] = useState([]);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getTransiontionList = () => {
    console.log(data.empid, "data.empid");
    setLoading(true);
    apiManager
      .transiontionList(data.empid)
      .then((response) => {
        setAdvansSalaryData(response?.data?.transionhistorie[0]);
      })
      .catch((error) => {
        console.log("Error fetching payroll data:", error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTransiontionList();
  }, [data.empid]);

  const getSalarySlip = () => {
    setLoading(true);
    apiManager
      .salarySlip(data._id)
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `SalarySlip_${data._id}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setmsgType("success");
        setShowMSG(response.data.message || "Details updated successfully");
      })
      .catch((error) => {
        console.error("Error downloading salary slip:", error);
        setmsgType("error");
        setShowMSG("Failed to download PaySlip");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayment = (status) => {
    setLoading(true);
    apiManager
      .changeStatus({ id: data._id, status })
      .then(() => {
        setIsOpen(!isOpen);
      })
      .catch((error) => {
        console.error(`Error ${status} payment:`, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paymentStatus = data.payment_status ? "Paid" : "unpaid";

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12 ps-5 pt-4">
                <img src={`${BASE_URL}/${data.image}`} alt="Asset" width="300" />
              </div>
              <div className="col-lg-12 pt-2">
                <ul>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Employee Name</span>
                    <span className="col-lg-6 pt-3 text-dark-emphasis">{data.empname || "NA"}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Employee Id</span>
                    <span className="col-lg-6 pt-3 text-dark-emphasis">{data.employeeID || "NA"}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Bonus</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data.bonus}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Insurance</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data.insurance}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Status</span>
                    <span className="col-lg-6 pt-3" style={{ color: paymentStatus === "unpaid" ? "red" : "green" }}>
                      {paymentStatus}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 pt-3">Present Days</div>
              <div className="col-lg-6 pt-3 text-dark-emphasis">{data.presentDay || "NA"}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Basic Salary</div>
              <div className="col-lg-6 pt-3 text-dark-emphasis">{data.baseSalary || "NA"}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Net Pay</div>
              <div className="col-lg-6 text-dark-emphasis pt-3">{data.netSalary}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Deduction</div>
              <div className="col-lg-6 pt-3 text-dark-emphasis">{data.totalDeductions || "NA"}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">HRA</div>
              <div className="col-lg-6 pt-3 text-dark-emphasis">{data.hra || "NA"}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Payment Mode</div>
              <div className="col-lg-6 pt-3 text-dark-emphasis">{data.payment_mode || "NA"}</div>
            </div>
            <div className="row">
              <div className="col-lg-12 pt-3">
                <h5>Advance Salary Detail:</h5>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Advance Salary</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalAmount || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Remaining EMI Amount</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.remainingAmount || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Total EMI</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.emiNumber || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Remaining EMI</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10 pb-2 pt-3">
            <div className="d-flex justify-content-end gap-2">
              {showPaySlipButton && paymentStatus !== "unpaid" && <Button label="Pay Slip" onClick={getSalarySlip} />}
              {showPayHoldButton && paymentStatus === "paid" && (
                <Button label="Hold" onClick={() => handlePayment("hold")} disabled={loading} />
              )}
              {showPayHoldButton && paymentStatus === "paid" && (
                <Button
                  label="Edit"
                  onClick={() => navigate("/hrtools/manage-fund/edit-salary/form", { state: { data } })}
                  disabled={loading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </div>
  );
}
