import axios from "axios";
import { endpoints } from "./endpoints";
import authApi from "./auth_axios";
import { type } from "@testing-library/user-event/dist/type";

class NetworkService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    this.authApi = authApi;
  }

  loginuser = async (data) => {
    return this.api.post(endpoints.LOGIN, data);
  };

  attendanceList = async (params) => {
    return this.authApi.get(endpoints.ATTENDANCE, { params });
  };

  rolesList = async () => {
    return this.authApi.get(endpoints.ROLES);
  };

  attendanceMark = async (data) => {
    return this.authApi.post(endpoints.ATTENDANCE, data);
  };

  addEmployee = async (data) => {
    return this.authApi.post(endpoints.ADDEMPLOYEE, data);
  };

  appEmployeeDepartment = async (data) => {
    return this.authApi.post(endpoints.ADDDEPARTMENTANDMAIL, data);
  };

  appEmployeeAccountDetails = async (data) => {
    return this.authApi.post(endpoints.ACCOUNTDETAILS, data);
  };

  appEmployeeCTCDetails = async (data) => {
    return this.authApi.post(endpoints.CTCDETAILS, data);
  };

  getUserInfo = async (data) => {
    return this.authApi.get(endpoints.USERINFO, data);
  };

  updateEmployee = async (data) => {
    return this.authApi.post(endpoints.UPDATEEMPLOYEE, data);
  };

  addEmployeeAddress = async (data) => {
    return this.authApi.post(endpoints.ADDEMPLOYEEADDRESS, data);
  };

  addEmployeeDocuments = async (data) => {
    return this.authApi.post(endpoints.ADDEMPLOYEEDOCS, data);
  };
  employeeList = async () => {
    return this.authApi.get(endpoints.EMPLOYEELIST);
  };

  activeEmployeeList = async (status) => {
    return this.authApi.get(endpoints.ACTIVEEMPLOYEES(status));
  };

  forgotpassword = async (data) => {
    return this.api.post(endpoints.FORGOTPASSWORD, data);
  };
  verifyotp = async (data) => {
    return this.api.post(endpoints.VERIFYOTP, data);
  };
  resend = async (data) => {
    return this.api.post(endpoints.RESEND, data);
  };
  resetpassword = async (data) => {
    return this.api.post(endpoints.RESETPASSWORD, data);
  };
  createLeave = async (data) => {
    return this.authApi.post(endpoints.CREATELEAVE, data);
  };
  leaveStatus = async (data) => {
    return this.authApi.post(endpoints.LEAVESTATUS, data);
  };
  LeaveList = async () => {
    return this.authApi.get(endpoints.LEAVELIST);
  };
  getDropdownTypes = async (type) => {
    return this.authApi.get(endpoints.GETDROPDOWNTYPES(type));
  };
  employeecount = async () => {
    return this.authApi.get(endpoints.EMPLOYEECOUNT);
  };
  Attendancetcalenderlist = async (param) => {
    return this.authApi.get(endpoints.ATTENDANCECALENDERHOLIDAYLIST, { params: param });
  };
  createdocument = async (param) => {
    return this.authApi.get(endpoints.CREATEDOCUMENT(param));
  };
  employeedocs = async (data) => {
    return this.authApi.post(endpoints.EMPLOYEEDOCS, data);
  };

  holidaylist = async (params) => {
    return this.authApi.get(endpoints.HOLIDAYLIST, {
      params: params,
    });
  };
  holidaypending = async (params) => {
    return this.authApi.get(endpoints.HOLIDAYPENDING, {
      params: params,
    });
  };
  holidayapprovied = async (data) => {
    return this.authApi.post(endpoints.HOLIDAYAPPROVED, data);
  };
  createholiday = async (data) => {
    return this.authApi.post(endpoints.CREATEHOLIDAY, data);
  };
  updateholiday = async (params) => {
    return this.authApi.post(endpoints.UPDATEHOLIDAY, params);
  };
  addbulkholiday = async (data) => {
    console.log(data, "data");
    return this.authApi.post(endpoints.ADDBULKHOLIDAY, data);
  };
  country = async () => {
    return this.authApi.get(endpoints.ALLCOUNTRY);
  };

  stateByName = async (name) => {
    return this.authApi.get(endpoints.ALLCORRESPONDINGSTATE(name));
  };

  state = async (name) => {
    return this.authApi.get(endpoints.ALLSTATESBYID(name));
  };

  city = async (id) => {
    return this.authApi.get(endpoints.ALLCORRESPONDINGCITY(id));
  };

  candidateDetails = async (id) => {
    return this.authApi.get(endpoints.CANDIDATEDETAILS(id));
  };
  PayrollView = async (id) => {
    return this.authApi.get(endpoints.PAYROLLVIEW(id));
  };
  candidateAddressDetails = async (id) => {
    return this.authApi.get(endpoints.CANDIDATEADDRESSDETAILS(id));
  };

  candidateDocuments = async (id) => {
    return this.authApi.get(endpoints.CANDIDATEDOCUMENTS(id));
  };

  candidateAllData = async (id) => {
    return this.authApi.get(endpoints.CANDIDATEALLDATA(id));
  };

  assetList = async () => {
    return this.authApi.get(endpoints.ASSETLIST);
  };

  assetAssign = async (data) => {
    return this.authApi.post(endpoints.ASSETASSIGN, data);
  };

  assetAssignList = async () => {
    return this.authApi.get(endpoints.ASSETASSIGNLIST);
  };

  AddAsset = async (data) => {
    return this.authApi.post(endpoints.ADDASSETS, data);
  };
  bulkAsset = async (data) => {
    return this.authApi.post(endpoints.BULKASSET, data);
  };

  revokeAsset = async (data) => {
    return this.authApi.post(endpoints.REVOKEASSET, data);
  };
  updateAsset = async (data) => {
    return this.authApi.post(endpoints.UPDATEASSETS, data);
  };
  getAsset = async (id) => {
    return this.authApi.get(endpoints.GETASSET(id));
  };
  verifyAsset = async (data) => {
    return this.authApi.post(endpoints.VERIFYASSETS, data);
  };
  assetInvantory = async () => {
    return this.authApi.get(endpoints.INVANTORY);
  };
  generateDocumnet = async (data) => {
    return this.authApi.get(endpoints.GENERATEDOCUMENT(data));
  };
  downloadDocumnet = async (data) => {
    return this.authApi.get(endpoints.DOWNLOADDOCUMENT(data), { responseType: "blob" });
  };
  ApprovedDocument = async (data) => {
    return this.authApi.post(endpoints.APPROVEDDOCUMENT, data);
  };
  Managefundtotalemployee = async () => {
    return this.authApi.get(endpoints.MANAGEFUNDTOTALEMPLOYEE);
  };
  OverallList = async (params) => {
    return this.authApi.get(endpoints.ATTENDANCEOVERALL, { params });
  };
  WeeklyAttendance = async () => {
    return this.authApi.get(endpoints.ATTENDANCEWEEKLY);
  };
  EmployeedataTable = async () => {
    return this.authApi.get(endpoints.EMPLOYEEDATATABLE);
  };
  EmployeeSalaryStatus = async () => {
    return this.authApi.get(endpoints.EMPLOYEESALARYSTATUS);
  };

  TodayApprovedList = async () => {
    return this.authApi.get(endpoints.ATTENDANCELEAVEAPPROVED);
  };
  AttendanceGraphList = async () => {
    return this.authApi.get(endpoints.ATTENDANCEGRAPHLIST);
  };
  OverallLeaveList = async () => {
    return this.authApi.get(endpoints.OVERALLLEAVELIST);
  };
  CheackInCheckOut = async () => {
    return this.authApi.post(endpoints.ATTENDANCEDASHBOARDINOUT);
  };
  PersonalInformation = async () => {
    return this.authApi.get(endpoints.PERSONALINFORMATIONATTENDANCE);
  };
  ManagefundPayrollList = async () => {
    return this.authApi.get(endpoints.MANAGEFUNDPAYROLLLIST);
  };
  PayrollCostEmployeecost = async () => {
    return this.authApi.get(endpoints.PAYROLLCOSTEMPLOYEECOST);
  };
  PayrollTotalDay = async () => {
    return this.authApi.get(endpoints.PAYROLLTOTALPAYDAYS);
  };
  ApprovedList = async () => {
    return this.authApi.get(endpoints.APPROVEDLIST);
  };
  changeStatus = async (data) => {
    return this.authApi.patch(endpoints.CHANGESTATUS, data);
  };

  CreateOffboarding = async (data) => {
    return this.authApi.post(endpoints.CREATEOFFBOARDING, data);
  };
  OffboardingPendingList = async (id) => {
    return this.authApi.get(endpoints.OFFBOARDINGPENDINGLIST(id));
  };
  OffboardingCompleteList = async () => {
    return this.authApi.get(endpoints.OFFBOARDINGCOMPLETELIST);
  };

  OffboardingStatus = async (data) => {
    return this.authApi.post(endpoints.OFFBOARDINGSTATUS, data);
  };
  createAdvanceSalary = async (data) => {
    return this.authApi.post(endpoints.CREATEADVANCESALARY, data);
  };
  advanceSalaryList = async () => {
    return this.authApi.get(endpoints.ADVANCESALARYLIST);
  };
  advanceSalaryView = async (id) => {
    return this.authApi.get(endpoints.ADVANCESALARYVIEW(id));
  };
  advanceSalaryStatus = async (data) => {
    return this.authApi.post(endpoints.ADVANCESALARYSTATUS, data);
  };
  salaryList = async (params) => {
    return this.authApi.get(endpoints.SALARYLIST, { params });
  };
  HrDashboardEmData = () => {
    return this.authApi.get(endpoints.HRDASHOBARD);
  };

  HrDepartmentCount = () => {
    return this.authApi.get(endpoints.HRDEPARTMENT);
  };
  HrDashboardYearly = (year) => {
    return this.authApi.post(endpoints.HRDASHBOARDYEARLYDATA, year);
  };
  HrDashboardAsset = () => {
    return this.authApi.get(endpoints.HRDASHBOARDASSEST);
  };
  HrOnboardingStatus = () => {
    return this.authApi.get(endpoints.HRONBAOARDING);
  };
  HrEmployeeStatus = () => {
    return this.authApi.get(endpoints.HREMPLOYEESTATUS);
  };
  updateSalary = async (data) => {
    return this.authApi.patch(endpoints.UPDATESALARY, data);
  };
  approvedSalary = async (data) => {
    return this.authApi.patch(endpoints.APPROVEDSALARY, data);
  };
  paySalary = async (data) => {
    return this.authApi.patch(endpoints.PAYSALARY, data);
  };
  salarySlip = async (id) => {
    return this.authApi.get(endpoints.SALARYSLIP(id), { responseType: "blob" });
  };
  transiontionList = async (id) => {
    console.log(id, "params");
    return this.authApi.get(endpoints.TRANSIONTIONLIST(id));
  };
  dayAttendanceList = () => {
    return this.authApi.get(endpoints.DAYATTENDANCELIST);
  };

  updateAttendance = async (data) => {
    return this.authApi.patch(endpoints.UPDATEATTENDANCE, data);
  };
  getDesignation = async (type) => {
    return this.authApi.get(endpoints.GETDESIGNATION(type));
  };
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

let apiManager = new NetworkService(BASE_URL);

export default apiManager;
