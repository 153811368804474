import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { lendingDashboardSideMenu, dashboardSideMenu, subHeaderMenu } from "../../common/json/MenuContent";
import { AiOutlineMenu } from "react-icons/ai";

const SidebarContent = () => {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState();
  const [activePath, setActivePath] = useState(location.pathname);
  const [activeSublink, setActiveSublink] = useState("disabled");
  const [menuClose, setMenuClose] = useState(false);

  useEffect(() => {
    const handleMediaChange = (e) => {
      setMenuClose(e.matches);
    };
    const mediaQuery = window.matchMedia("(max-width: 990px)");
    mediaQuery.addEventListener("change", handleMediaChange);
    setMenuClose(mediaQuery.matches);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  useEffect(() => {
    setMenuItems(location.pathname === "/" ? lendingDashboardSideMenu : dashboardSideMenu);
  }, [location]);

  const handleMenuItemClick = (item, index) => {
    setActivePath(item.path);
  };

  const handleSubItemClick = (subItem) => {
    setActiveSublink(subItem.link);
  };

  return (
    <div className={`sideBar ${menuClose ? "sideBar-close" : ""}`}>
      <hr
        style={{
          color: "#827B7B",
          marginLeft: "1.3rem",
          marginRight: "1.3rem",
          marginTop: 0,
        }}
      />
      <div className="d-flex justify-content-end pointer" onClick={() => setMenuClose(!menuClose)}>
        <AiOutlineMenu />
      </div>
      <div className="flex-column d-flex justify-content-center align-items-center">
        {menuItems !== lendingDashboardSideMenu ? (
          <div className="border border-primary p-2 m-5 side-bar--icon">
            <NavLink to="/hrtools" style={{ textDecoration: "none", color: "var(--orrenge)" }}>
              <h6 className=" fw-semibold lh-1">HR</h6> <p className="side-bar--icon---p">Dashboard</p>
            </NavLink>
          </div>
        ) : (
          <div className="border border-primary p-2 m-5 side-bar--icon">
            <NavLink to="" style={{ textDecoration: "none", color: "var(--orrenge)" }}>
              <h6 className="fw-semibold lh-1">Ee</h6> <p className="side-bar--icon---p">Dashboard</p>
            </NavLink>
          </div>
        )}

        <ul className="text-start p-1 py-2 mt-3">
          {menuItems &&
            menuItems.map((item, index) => (
              <li key={index} className="text-nowrap p-1 list-unstyled side-bar--button">
                <NavLink
                  to={item.path}
                  onClick={() => handleMenuItemClick(item, index)}
                  className={`align-items-center gap-2 py-2 ${activePath === item.path ? "activenavlink" : ""}`}
                >
                  <span>{item.icon}</span>
                  {!menuClose && <span className="ps-2">{item.title}</span>}

                  {activePath === item.path && !menuClose && (
                    <ul className="list-unstyled ps-3">
                      {item.subLinks &&
                        item.subLinks.map((subItem, i) => (
                          <NavLink to={subItem.link} key={i}>
                            <li
                              className={`sub-li ${activeSublink === subItem.link ? "activeSublink" : ""}`}
                              onClick={() => handleSubItemClick(subItem)}
                            >
                              <div>{subItem.title}</div>
                            </li>
                          </NavLink>
                        ))}
                    </ul>
                  )}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarContent;
