import React, { useEffect, useState } from "react";
import totalEmployee from "../../../../src/assets/images/totalEmployee.png";
import totalPresent from "../../../assets/images/totalPresent.png";
import totalEmpolyees2 from "../../../../src/assets/images/totalEmployees2.png";
import totalabsent from "../../../../src/assets/images/totalabsent.png";
import totalpresents from "../../../../src/assets/images/totalpresents.png";
import leaverequest from "../../../assets/images/leaverequest.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";
import "../../../assets/style/attendanceTracking.css";
import SubHeader from "../../../UI_Components/SubHeader";
import { Piedata, chartData, pie_option, chart_options } from "../../../Components/GraphData/GraphContent";
import apiManager from "networking/baseAPIManager";
import { CoreLoader, Input, Label } from "common/components";
import { SCard } from "UI_Components";
import Charts from "common/components/Chart";
import CalendarApp from "common/components/CalendarApp";
import "../../../assets/style/Responsive.css";
import AttendanceTrackingReport from "Components/AttendaceReport/AttendanceTrackingReport";
import moment from "moment";
export default function AttendanceDashboard() {
  const [titleData, setTitleData] = useState();
  const [todayListData, setTodatlistData] = useState([]);
  const [graphlistData, setGraphlistData] = useState([]);
  const [overallListData, setOverallListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([]);
  const [presentData, setPresentData] = useState([]);
  const [absentData, setAbsentData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [dayAttendance, setDayAttendance] = useState([]);

  const navigate = useNavigate();

  let BASE_URL = process.env.REACT_APP_BASE_URL;

  const tools = [
    {
      id: 1,
      image: totalEmpolyees2,
      cardContent: `${titleData?.totalEmployeeCount}`,
      cardTitle: "TotalEmployees",
    },
    {
      id: 2,
      image: totalpresents,
      cardTitle: "Present ",
      cardContent: `${titleData?.TotalEmployeePresent}`,
    },
    {
      id: 3,
      image: totalabsent,
      cardContent: `${titleData?.TotalEmployeeAbsent}`,
      cardTitle: "Absent ",

      carryData: "leave",
    },
    {
      id: 4,
      image: leaverequest,
      cardContent: `${titleData?.totalEmployeeLeaveToday}`,
      cardTitle: " On Leave",
    },
  ];

  const donutoption = {
    series: overallListData?.series,
    // series: [44, 55, 41],
    chart: {
      type: "donut",
      width: "10%",
    },
    labels: overallListData?.status,

    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            // total: {
            //   showAlways: true,
            //   show: true,
            // },
          },
        },
      },
    },
    legend: {
      fontSize: "16px",
      fontFamily: "Arial, sans-serif",
      fontWeight: "600",
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 80,
        options: {
          chart: {},
          legend: {
            // position: "bottom",
          },
        },
      },
    ],
  };

  const option = {
    series: [
      {
        name: "Total Employee",
        data: totalData,
      },
      {
        name: "Present",
        data: presentData,
      },
      {
        name: "Absent",
        data: absentData,
      },
    ],

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Employee Status",
      align: "left",
      style: {
        fontWeight: "600",
        fontSize: "20px",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "35%",
        endingShape: "rounded",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacity: 1,
    //   },
    // },
    // yaxis: {
    //   title: {
    //     text: "$ (thousands)",
    //   },
    // },
    xaxis: {
      categories: date,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  useEffect(() => {
    fetchTitleData();
    approvedTodayList();
    graphlistattendance();
    overallLeaveList();
    dayWiseAttendance();
  }, []);

  const fetchTitleData = () => {
    setLoading(true);

    apiManager
      .employeecount()
      .then((response) => {
        setLoading(false);
        setTitleData(response.data);
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const approvedTodayList = () => {
    setLoading(true);

    apiManager
      .TodayApprovedList()
      .then((response) => {
        setLoading(false);
        setTodatlistData(response.data);
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const moment = require("moment");
  const graphlistattendance = () => {
    setLoading(true);

    apiManager
      .AttendanceGraphList()
      .then((response) => {
        setLoading(false);
        setGraphlistData(response?.data);
        const present = response?.data?.map((item) => item.present);
        const absent = response?.data?.map((item) => item.absent);
        const total = response?.data?.map((item) => item.total);
        const graphdate = response?.data?.map((item) => moment(item.date).format("ll"));
        setPresentData(present);
        setAbsentData(absent);
        setTotalData(total);
        setDate(graphdate);
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const overallLeaveList = () => {
    setLoading(true);

    apiManager
      .OverallLeaveList()
      .then((response) => {
        setLoading(false);
        setOverallListData(response?.data);
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const dayWiseAttendance = () => {
    apiManager
      .dayAttendanceList()
      .then((response) => {
        setDayAttendance(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <div className="d-flex flex-column  ">
      <SubHeader location={useLocation()} />
      <div className="container-fluid">
        <div className="row">
          <SCard tools={tools} titleClass="text-light" contentClass="text-light" />
        </div>
        <div className="row g-3  ">
          <div className=" col-lg-12  col-xl-8">
            <div className=" main_table  p-3">
              <div className="h-[300px]  ">
                {graphlistData.length > 0 && <Charts option={option} type="bar" height={300} />}
              </div>
            </div>
          </div>

          <div className=" col-lg-12  col-xl-4 border rounded-4  chartboxshadow ">
            <h5 className="fw-bold mt-4">Overall Leave</h5>
            <div className="flex justify-center item-center ">
              {overallListData?.series?.length > 0 && <Charts option={donutoption} type="donut" height={240} />}
            </div>
          </div>
        </div>

        <div className="row g-3 pt-3 ">
          <div className=" col-lg-12  col-xl-8 ">
            <div className=" main_table  p-3">
              <div style={{}}>
                <CalendarApp size="md" />
              </div>
            </div>
          </div>
          <div
            className=" col-xl-4 col-md-12  border rounded-4 overflow-auto chartboxshadow responsive14"
            style={{ height: "450px" }}
          >
            <div className="flex justify-center item-center  p-3">
              <div className="d-flex justify-content-between">
                <span className="pt-2">Attendance Report</span>
                {/* <span className="">
                  <Input type="date" />
                </span> */}
              </div>
              {dayAttendance &&
                dayAttendance?.map((data, index) => {
                  const fullName = `${data?.firstname} ${data?.middlename} ${data?.lastname} `;
                  const leaveType =
                    data?.attendance[0]?.leave_type === "P"
                      ? "Present"
                      : data?.attendance[0]?.leave_type === "A"
                      ? "Absent "
                      : data?.attendance[0]?.leave_type === "L"
                      ? "OnLeave"
                      : "NA";
                  const color =
                    data?.attendance[0]?.leave_type == "P"
                      ? "green"
                      : data?.attendance[0]?.leave_type == "A"
                      ? "red"
                      : data?.attendance[0]?.leave_type == "L"
                      ? "#F4BB44"
                      : "blue";
                  return (
                    <>
                      <div className="row p-3  ">
                        <div className="col-xl-2 col-md-3 col-sm-2">
                          <span className="d-flex align-item-center">
                            <img
                              className="rounded-circle mt-3 "
                              src={`${BASE_URL}/${data?.image}`}
                              alt="Image"
                              width="30px"
                              height="30px"
                            />
                          </span>
                        </div>
                        <div className="col-xl-7 col-md-6 col-sm-7 ">
                          <h6 className="pt-2"> {`${fullName}`}</h6>
                          <span cla>{moment(data?.attendance[0]?._doc?.date).format("DD MMM YYYY")}</span>
                        </div>

                        <div className="col-xl-3 col-md-3 col-sm-3" style={{ color: color }}>
                          {leaveType}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <AttendanceTrackingReport />
        </div>
      </div>
      <CoreLoader show={loading} />
    </div>
  );
}
